import React from 'react'
import './Footer.sass'

function Footer() {
    return (
        <div className="Footer">
            Made by Me with React
        </div>
    )
}

export default Footer
